'use client';

import Metal18KSvgIcon from '@/components/Icons/Svg/Metal18KSvgIcon';
import ScaleSvgIcon from '@/components/Icons/Svg/ScaleSvgIcon';
import colors from '@/constants/theme/colors';
import { polySansWide } from '@/fonts';
import {
  Button,
  Flex,
  Text,
  Box,
  Grid,
  GridItem,
  Spinner,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';

declare global {
  interface Window {
    _klOnsite: any[];
  }
}

interface LandingProps {}
const Landing: FC<LandingProps> = () => {
  const [isBgLoaded, setIsBgLoaded] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const values = [
    {
      description: 'Early access to our fine 18k solid gold collection',
      renderIcon: () => (
        <Box position="absolute" top="0.5rem">
          <Metal18KSvgIcon width="4rem" fill={colors.secondary[50]} />
        </Box>
      ),
    },
    {
      description: 'Exclusive sneak peeks and behind-the-scenes content',
      renderIcon: () => (
        <ScaleSvgIcon width="3rem" height="3rem" fill={colors.secondary[50]} />
      ),
    },
    {
      description: 'Special offers and VIP perks',
      renderIcon: () => (
        <Text
          className={polySansWide.className}
          fontWeight="600"
          color={colors.secondary[50]}
          fontSize="2rem"
          lineHeight="3rem"
        >
          VIP
        </Text>
      ),
    },
  ];

  const showLoading = !isBgLoaded || !isImgLoaded;

  return (
    <>
      {showLoading && (
        <Flex
          w="100%"
          h="100%"
          position="absolute"
          bgColor={colors.primary[25]}
          zIndex="10"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color={colors.white} size="lg" />
        </Flex>
      )}
      <Flex
        w="100vw"
        h="100dvh"
        p={{ base: '0.5rem', xl: '1rem', '2xl': '1.5rem' }}
        gap={{ base: '0.5rem', xl: '0' }}
        bgColor={colors.white}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Flex
          alignItems="center"
          h="100%"
          flexDir="column"
          flex="1"
          gap="1rem"
          p="1rem"
          position="relative"
        >
          <Image
            src="https://cdn.aurev.co/f1804995616fd57f-landing-bg.webp"
            alt="landing-bg"
            fill
            priority
            unoptimized
            loading="eager"
            quality={100}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            onLoad={() => setIsBgLoaded(true)}
          />
          <Flex
            alignItems="center"
            flexDir="column"
            gap={{ base: '1.5rem', xl: '3rem' }}
            justifyContent="center"
            flex="2"
            zIndex="10"
            opacity={isBgLoaded ? '1' : '0'}
          >
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <Box
                w={{ base: '300px', '2xl': '600px' }}
                h={{ base: '99px', '2xl': '198px' }}
                position="relative"
              >
                <Image
                  src="/text-logo-white.svg"
                  alt="logo"
                  fill
                  sizes="(max-width: 992px) 100vw, 600px"
                  quality={100}
                  priority
                  loading="eager"
                />
              </Box>
              <Text
                color="#D0DADC"
                fontSize="1rem"
                fontWeight="300"
                textAlign="center"
              >
                Coming Soon - Join Our Exclusive Waitlist for
              </Text>
            </Flex>
            <Grid
              templateColumns={{
                base: 'repeat(3, 1fr)',
                xl: 'repeat(3, 1fr)',
              }}
              gap="0.75rem"
              position="relative"
            >
              {values.map(({ description, renderIcon }) => (
                <GridItem key={description}>
                  <Flex
                    flexDir="column"
                    h="100%"
                    bgColor="rgba(255, 255, 255, 0.04)"
                    gap="0.5rem"
                    p={{ base: '0.75rem', '2xl': '1.25rem' }}
                    flex="1"
                  >
                    <Box h="3rem">{renderIcon()}</Box>
                    <Flex
                      flexDir="column"
                      gap="0.25rem"
                      flex={{ base: '2', xl: '0' }}
                    >
                      <Text
                        color={colors.white}
                        fontSize={{ base: '0.875rem', xl: '0.75rem' }}
                        lineHeight={{ base: '1.25rem', xl: '1rem' }}
                        whiteSpace="pre-wrap"
                      >
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
            <Flex flexDir="column" gap="1rem" alignItems="center" w="100%">
              <Button
                variant="secondaryMd"
                color={colors.white}
                borderColor={colors.white}
                _hover={{
                  borderColor: colors.white,
                }}
                bg="rgba(255, 255, 255, 0.16)"
                className={polySansWide.className}
                onClick={() => {
                  window._klOnsite = window._klOnsite || [];
                  window._klOnsite.push(['openForm', 'QZfWVb']);
                }}
                w="calc((100% - 0.75rem * 2)/3)"
              >
                Sign up
              </Button>
              <Link href="https://www.instagram.com/aurevco/" target="_blank">
                <Box as="button" cursor="pointer">
                  <InstagramIcon />
                </Box>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Box flex="1" position="relative">
          <Image
            src="https://cdn.aurev.co/9718c9f0951f477a-landing-img.webp"
            unoptimized
            loading="eager"
            alt="landing-img"
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            onLoad={() => setIsImgLoaded(true)}
            quality={100}
          />
        </Box>
      </Flex>
    </>
  );
};

const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      viewBox="0 0 24 24"
      fill={colors.white}
    >
      <title>instagram</title>
      <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
    </svg>
  );
};

export default Landing;
