import appApis, { APP_QUERY_TAG } from '@/store/apis/app';
import { IAppCartItem, ICartCoupon, ICartGiftCard } from '@/types/api/app/cart';
import { MutationParams } from '@/types/api/common';
import { appActions } from '../slices/app';
import dayjs from 'dayjs';
import { FilterBuilder } from '@loopback/filter';

export interface IPostCartCheckoutResponse {
  cartItems: IAppCartItem[];

  customerId: number;
  lockedAt: string;

  stripeClientSecret: string;
  stripePaymentIntentId: string;

  totalPrice: number;
  lastCheckedOutAt: string;
  lockedExpiredAt: string;
  giftCardCode: string;
  couponCode: string;
}

export interface IPublicCartCheckoutResponse {
  lockedAt: string;
  lockedExpiredAt: string;
  stripePaymentIntentId: string;
  stripeClientSecret: string;
  cartItems: IAppCartItem[];
  code: string;
  giftCardCode: string | null;
  giftCard: ICartGiftCard | null;
  couponCode: string | null;
  coupon: ICartCoupon | null;
  totalPrice: number;
  shippingPrice: number;
}

export interface IPostCartCheckoutReqBody
  extends Partial<
    Pick<
      IPublicCartCheckoutResponse,
      | 'cartItems'
      | 'stripeClientSecret'
      | 'stripePaymentIntentId'
      | 'code'
      | 'shippingPrice'
    >
  > {}

export interface IPostCartMeItemsRequestBody {
  id?: string;
  name?: string;
  quantity: number;
  productVariantId: number;
  metadata?: {
    recipientEmail: string;
    recipientName: string;
    message: string;
  };
}

export interface IGetCartMeResponse {
  cartItems: IAppCartItem[];

  totalPrice: number;
  lastCheckedOutAt: string;
  lockedAt: string;
  lockedExpiredAt: string;
  customerId: number;
  stripePaymentIntentId: string;
  stripeClientSecret: string;
  giftCardCode: string | null;
  giftCard: ICartGiftCard | null;
  couponCode: string | null;
  coupon: ICartCoupon | null;
}

const appCartsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query<IGetCartMeResponse, void>({
      query: () => {
        const filterBuilder = new FilterBuilder();
        filterBuilder.include({
          relation: 'productVariants',
        });
        const filter = filterBuilder.build();

        return {
          url: '/carts/me',
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      extraOptions: {
        maxRetries: 0,
      },
      providesTags: [APP_QUERY_TAG.cart],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const { lockedExpiredAt } = data;
        dispatch(appActions.setPriceLockTimerDeadline(dayjs(lockedExpiredAt)));
      },
    }),
    deleteCart: build.mutation<{}, void>({
      query: () => {
        return {
          url: '/carts/me',
          method: 'DELETE',
        };
      },
      invalidatesTags: [APP_QUERY_TAG.cart],
    }),
    addCartItem: build.mutation<
      IPostCartCheckoutResponse,
      { body: IPostCartMeItemsRequestBody }
    >({
      query: ({ body }) => ({
        url: '/carts/me/items',
        method: 'POST',
        body,
      }),
      invalidatesTags: [APP_QUERY_TAG.cart],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const { lockedExpiredAt } = data;
        dispatch(appActions.setPriceLockTimerDeadline(dayjs(lockedExpiredAt)));
      },
    }),
    addCartDiscount: build.mutation<
      IPostCartCheckoutResponse,
      {
        code: string;
      }
    >({
      query: ({ code }) => ({
        url: '/carts/me/discount',
        method: 'PATCH',
        body: {
          code,
        },
      }),
      invalidatesTags: [APP_QUERY_TAG.cart],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const { lockedExpiredAt } = data;
        dispatch(appActions.setPriceLockTimerDeadline(dayjs(lockedExpiredAt)));
      },
    }),
    removeCartDiscount: build.mutation<
      IPostCartCheckoutResponse,
      {
        giftCardCode?: string;
        couponCode?: string;
      }
    >({
      query: ({ giftCardCode, couponCode }) => ({
        url: '/carts/me/discount/remove',
        method: 'PATCH',
        body: {
          giftCardCode,
          couponCode,
        },
      }),
      invalidatesTags: [APP_QUERY_TAG.cart],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const { lockedExpiredAt } = data;
        dispatch(appActions.setPriceLockTimerDeadline(dayjs(lockedExpiredAt)));
      },
    }),
    deleteCartItem: build.mutation<
      void,
      MutationParams<{}, { cartItemId: string }>
    >({
      query: ({ params: { cartItemId } }) => ({
        url: `/carts/me/items/${cartItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [APP_QUERY_TAG.cart],
    }),
    checkoutCart: build.mutation<
      IPostCartCheckoutResponse,
      {
        shippingPrice?: number;
        useCredit?: boolean;
        code?: string;
        stripePaymentIntentId?: string;
        stripeClientSecret?: string;
      } | void
    >({
      query: (params) => ({
        url: '/carts/me/checkout',
        method: 'POST',
        body: !!params
          ? JSON.stringify({
              ...params,
            })
          : JSON.stringify({}),
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const { lockedExpiredAt } = data;
        dispatch(appActions.setPriceLockTimerDeadline(dayjs(lockedExpiredAt)));
      },
    }),
    checkoutPublicCart: build.mutation<
      IPublicCartCheckoutResponse,
      MutationParams<IPostCartCheckoutReqBody>
    >({
      query: ({ body }) => ({
        url: '/carts/checkout',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCartQuery,
  useAddCartItemMutation,
  useAddCartDiscountMutation,
  useCheckoutCartMutation,
  useCheckoutPublicCartMutation,
  useDeleteCartItemMutation,
  useDeleteCartMutation,
  useRemoveCartDiscountMutation,
} = appCartsApis;

export default appCartsApis;
