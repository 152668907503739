import { IBaseEntity } from '@/types/api/app/common';

export interface IAppProductVariant extends IBaseEntity {
  metal: Metal | `${Metal}:${Metal}`;
  metalWeight: number;
  totalWeight: number;
  size: string;
  sku: string;
  price: number;
  priceCurrency: string;
  productH: number;
  productW: number;
  productL: number;
  packingH: number;
  packingW: number;
  packingL: number;
  availableQuantity: number;
  incoming: number;
  committed: number;
  restockDate: string;
  stockAvailability: StockAvailability;
  alloyMakeup?: string;
  subtitle?: string;

  productId: number;
  product: IAppProductWithVariants;

  photoOrVideoUrls?: string[];
  hoveringPhotoUrls?: string[];
  metadata?: {
    fields: { label: string; value: string }[];
  };
  groupKey?: string;
  isPreOrderAllowed?: boolean;
  preOrderTitle?: string;
  preOrderDescription?: string;
  ymalProductVariantIds?: number[];
  title: string | null;
}

export interface IAppProduct extends IBaseEntity {
  title: string;
  slug: string;
  subtitle: string;
  photoOrVideoUrls: string[];
  supportingPhotoUrls: string[];
  description: string;
  status: AppProductStatus;
  madeIn: string;
  variantGroupKeys: Array<'metal' | 'size'>;
}

export interface IAppProductWithVariants extends IAppProduct {
  productVariants: IAppProductVariant[];
}

export enum AppProductStatus {
  InStock = 'in-stock',
  OutOfStock = 'out-of-stock',
  Deactivated = 'deactivated',
}

export enum StockAvailability {
  InStock = 'in-stock',
  OutOfStock = 'out-of-stock',
  Deactivated = 'deactivated',
}

export enum Metal {
  YellowGold18K = 'yellow-gold-18k',
  YellowGold24K = 'yellow-gold-24k',
  WhiteGold18K = 'white-gold-18k',
  Platinum = 'platinum',
}
