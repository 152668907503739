import appApis, { APP_QUERY_TAG } from '@/store/apis/app';
import {
  AppProductStatus,
  IAppProduct,
  IAppProductWithVariants,
  StockAvailability,
} from '@/types/api/app/product';
import { IRequestFilter, buildFilter } from '@/utils/loopback-filter';
import { FilterBuilder, WhereBuilder } from '@loopback/filter';

const appProductsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    // products
    getProducts: build.query<IAppProductWithVariants[], IRequestFilter>({
      query: (filter) => {
        const filterBuilder = buildFilter(filter).filterBuilder;
        filterBuilder.include({
          relation: 'productVariants',
          scope: {
            where: {
              stockAvailability: {
                neq: StockAvailability.Deactivated,
              },
            },
          },
        });

        const filterObj = filterBuilder.build();

        return {
          url: '/products',
          method: 'GET',
          params: {
            filter: JSON.stringify(filterObj),
          },
        };
      },
      transformResponse: (response: IAppProductWithVariants[]) => {
        if (!response) return [];
        return response;
      },
      providesTags: [APP_QUERY_TAG.products],
    }),
    getInStockProductIds: build.query<number[], void>({
      query: () => {
        const filterBuilder = new FilterBuilder();
        const whereBuilder = new WhereBuilder();
        whereBuilder.neq('status', AppProductStatus.Deactivated);
        const where = whereBuilder.build();
        filterBuilder.where(where);

        const filter = filterBuilder.build();

        return {
          url: '/products',
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      transformResponse: (response: IAppProduct[]) => {
        if (!response) return [];
        return response.map(({ id }) => id);
      },
      providesTags: [APP_QUERY_TAG.inStockProductIds],
    }),
    getProductsCount: build.query<number, IRequestFilter>({
      query: (filter) => ({
        url: '/products/count',
        method: 'GET',
        params: {
          filter: buildFilter(filter).encoded,
        },
      }),
      transformResponse: (response: { count: number }) => {
        const { count } = response;
        return count;
      },
      providesTags: [APP_QUERY_TAG.products],
    }),
    getProductBySlug: build.query<IAppProductWithVariants, string>({
      query: (slug) => {
        const filterBuilder = new FilterBuilder();
        filterBuilder.include({
          relation: 'productVariants',
          scope: {
            where: {
              stockAvailability: {
                neq: StockAvailability.Deactivated,
              },
            },
          },
        });
        const filter = filterBuilder.build();

        return {
          url: `/products/slugs/${slug}`,
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: (product) => [
        { type: APP_QUERY_TAG.productBySlug, id: product?.slug },
      ],
    }),
    searchProducts: build.query<IAppProduct[], { searchText: string }>({
      query: ({ searchText }) => {
        return {
          url: `/products/search?k=${searchText}`,
          method: 'GET',
        };
      },
      providesTags: [APP_QUERY_TAG.productsSearch],
    }),
    getGiftCard: build.query<IAppProduct, void>({
      query: () => {
        return {
          url: '/products/gift-card',
          method: 'GET',
        };
      },
      providesTags: [APP_QUERY_TAG.products],
    }),

    getProductsByIds: build.query<IAppProductWithVariants[], { ids: number[] }>(
      {
        query: ({ ids }) => {
          const filterBuilder = new FilterBuilder();
          const whereBuilder = new WhereBuilder();

          filterBuilder.include({
            relation: 'productVariants',
          });

          whereBuilder.inq('id', ids);
          whereBuilder.neq('status', AppProductStatus.Deactivated);
          const where = whereBuilder.build();
          filterBuilder.where(where);

          const filter = filterBuilder.build();

          return {
            url: '/products',
            method: 'GET',
            params: {
              filter: JSON.stringify(filter),
            },
          };
        },
        transformResponse: (response: IAppProductWithVariants[]) => {
          if (!response) return [];
          return response;
        },
        providesTags: (result = []) => {
          if (!result) {
            return [{ type: APP_QUERY_TAG.products, id: '*' }];
          }
          return [
            { type: APP_QUERY_TAG.products, id: '*' },
            ...result.map(
              ({ id }) => ({ type: APP_QUERY_TAG.products, id } as const)
            ),
          ];
        },
      }
    ),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductsCountQuery,
  useGetProductBySlugQuery,
  useSearchProductsQuery,
  useGetInStockProductIdsQuery,
  useGetGiftCardQuery,
  useGetProductsByIdsQuery,
} = appProductsApis;

export default appProductsApis;
