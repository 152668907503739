import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';
import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const primaryVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    display: 'table',
    _hover: {
      overflow: 'auto',
    },
    width: '100%',
  },
  thead: {
    position: 'sticky',
    zIndex: 10,
    top: 0,
    bgColor: colors.background,
    tr: {
      zIndex: 10,
      bgColor: 'rgba(71, 73, 114, 0.04)',
      th: {
        borderLeft: 'none',
        borderRight: 'none',
        _first: {},
        _last: {},
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        color: colors.grey[500],
        textTransform: 'capitalize',
        w: 'max-content',
        whiteSpace: 'nowrap',
        px: '1rem',
      },
    },
  },
  tbody: {
    w: '100%',
    tr: {
      td: {
        fontSize: '0.9375rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
        color: colors.grey[900],
        bgColor: colors.white,
        borderTop: `0.25rem solid ${colors.background}`,
        px: '1rem',

        _first: {},
        _last: {},
      },
      _first: {},
      _last: {
        td: {
          _first: {},
          _last: {},
        },
      },
      _notLast: {},
    },
  },
}));

const TableStyles: ComponentStyleConfig = {
  baseStyle: {
    th: {
      _hover: {},
    },
    tr: {},
    td: {},
  },
  variants: {
    primary: primaryVariant,
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default TableStyles;
