import appApis, { APP_QUERY_TAG } from '@/store/apis/app';
import { IAppProductVariant, StockAvailability } from '@/types/api/app/product';
import { IRequestFilter, buildFilter } from '@/utils/loopback-filter';
import { Where } from '@loopback/filter';
import { AnyObject } from '@loopback/filter/dist/types';

const appProductVariantsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    getProductVariantsInfinite: build.query<
      IAppProductVariant[],
      IRequestFilter & { includeGiftCard?: boolean; uniqueGroupKey?: boolean }
    >({
      query: ({
        includeGiftCard = false,
        uniqueGroupKey = true,
        ...filter
      }) => {
        return {
          url: '/product-variants',
          method: 'GET',
          params: {
            filter: buildFilter(filter, undefined, {
              relation: 'product',
              scope: {
                include: [
                  {
                    relation: 'productVariants',
                    scope: {
                      where: {
                        stockAvailability: {
                          neq: StockAvailability.Deactivated,
                        },
                      },
                    },
                  },
                ],
              },
            }).encoded,
            includeGiftCard,
            uniqueGroupKey,
          },
        };
      },
      providesTags: (result = []) => {
        if (!result)
          return [
            { type: APP_QUERY_TAG.productVariants, infinite: true, id: '*' },
          ];
        return [
          ...result.map(({ id }) => {
            return {
              type: APP_QUERY_TAG.productVariants,
              infinite: true,
              id,
            } as const;
          }),
        ];
      },
      transformResponse: (response: IAppProductVariant[]) => {
        if (!response) return [];
        return response;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page, pageSize, ...rest } = queryArgs;
        return `${endpointName}-${JSON.stringify(rest)}`;
      },
      merge: (currentCache, newItems) => {
        currentCache.push(...newItems);
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
    }),

    getProductVariants: build.query<
      IAppProductVariant[],
      IRequestFilter & {
        includeGiftCard?: boolean;
        uniqueGroupKey?: boolean;
      }
    >({
      query: ({
        includeGiftCard = false,
        uniqueGroupKey = true,
        ...filter
      }) => {
        return {
          url: '/product-variants',
          method: 'GET',
          params: {
            filter: buildFilter(filter, undefined, {
              relation: 'product',
              scope: {
                include: [
                  {
                    relation: 'productVariants',
                    scope: {
                      where: {
                        stockAvailability: {
                          neq: StockAvailability.Deactivated,
                        },
                      },
                    },
                  },
                ],
              },
            }).encoded,
            includeGiftCard,
            uniqueGroupKey,
          },
        };
      },
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
      transformResponse: (response: IAppProductVariant[]) => {
        if (!response) return [];
        return response;
      },
    }),
    getGiftCardVariants: build.query<IAppProductVariant[], void>({
      query: () => {
        return {
          url: '/product-variants/gift-card',
          method: 'GET',
        };
      },
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
    }),
    getBestSellingProductVariants: build.query<
      IAppProductVariant[],
      IRequestFilter
    >({
      query: (filter) => ({
        url: '/product-variants/best-selling',
        method: 'GET',
        params: {
          filter: buildFilter(filter, undefined, ['product']).encoded,
        },
      }),
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
    }),
    getProductVariantsCount: build.query<
      number,
      {
        where?: Where<AnyObject>;
        uniqueGroupKey?: boolean;
      }
    >({
      query: ({ where, uniqueGroupKey = true }) => {
        return {
          url: '/product-variants/count',
          method: 'GET',
          params: {
            where: JSON.stringify(where),
            includeGiftCard: false,
            uniqueGroupKey,
          },
        };
      },
      transformResponse: (response: { count: number }) => {
        if (!response) return 0;
        const { count } = response;
        return count;
      },
      providesTags: [APP_QUERY_TAG.productVariantsCount],
    }),
    getProductVariantRetailEstimate: build.query<number,{
      id: number;
    }>({
      query: ({id}) => ({
        url: `/product-variants/${id}/retail-estimate`,
        method: 'GET',
      }),
      
    }),
    subscribeProductVariant: build.mutation<
      void,
      { id: number; email?: string }
    >({
      query: ({ id, email }) => ({
        url: `/product-variants/${id}/subscribe`,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useGetProductVariantsCountQuery,
  useGetProductVariantsInfiniteQuery,
  useGetProductVariantsQuery,
  useGetBestSellingProductVariantsQuery,
  useGetGiftCardVariantsQuery,
  useGetProductVariantRetailEstimateQuery,
  useSubscribeProductVariantMutation,
} = appProductVariantsApis;

export default appProductVariantsApis;
